import React, { useEffect, useState } from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import { RichText as PrismicRichText } from "prismic-reactjs";

import { columnPush, fluidType } from "utils";
import {
  AnthemUnitedLogo,
  Button,
  H1,
  H2,
  Image,
  RichText,
  Section,
  SEO,
  SplitImage,
  Text,
  WalkingHomeSvg,
  FadeGroup,
  FadeInGroup,
  Popup,
} from "components";

import { serializer, linkResolver } from "utils";

function IndexPage({ revealChildren, preview }) {
  // const { page } = useStaticQuery(HOME_QUERY);
  const [showPopup, setShowPopup] = useState(false);

  let popupTimeout;

  useEffect(() => {
    if (revealChildren) {
      setTimeout(() => {
        setShowPopup(true);
      }, 4000);
    }
    return function cleanup() {
      if (popupTimeout) {
        clearTimeout(popupTimeout);
      }
    };
  }, [revealChildren]);

  return (
    <StaticQuery
      query={graphql`
        {
          prismicHome {
            dataString
            data {
              hero_image {
                fluid(maxWidth: 2048) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              pinterest_main_image {
                fluid(maxWidth: 1600) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              section_one_image_one {
                fluid(maxWidth: 1600) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              section_one_image_two {
                fluid(maxWidth: 1600) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              section_two_main_image {
                fluid(maxWidth: 2048) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              anthem_main_image {
                fluid(maxWidth: 1600) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              builders {
                logo {
                  fluid(maxWidth: 400) {
                    ...GatsbyPrismicImageFluid
                  }
                  alt
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        let page = JSON.parse(data.prismicHome.dataString);
        // let page = data.prismicHome.data;
        if (preview) {
          page = preview;
        }
        const imageData = data.prismicHome.data;

        // console.log(data.prismicHome.data);
        // console.log(page.hero_image);
        // console.log(page);

        return (
          <>
            <SEO
              image={page.seo_image && page.seo_image.url}
              title={page.seo_title && PrismicRichText.asText(page.seo_title)}
              description={
                page.seo_description &&
                PrismicRichText.asText(page.seo_description)
              }
            />
            <section
              css={`
                width: 100%;
                height: 100vh;
                overflow: hidden;

                @media (max-width: 40em) {
                  height: 87.5vh;
                }
              `}
            >
              <Image
                src={
                  preview ? page.hero_image && page.hero_image.url : undefined
                }
                fluid={imageData.hero_image.fluid}
                alt={imageData.hero_image && imageData.hero_image.alt}
                loading="eager"
                style={{
                  width: "100%",
                  height: "100%",
                  transition: "transform 1.5s ease-out",
                  transform: revealChildren
                    ? "scale3d(1.05, 1.05, 1)"
                    : "scale3d(1, 1, 1)",
                }}
              />
            </section>
            {page.hero_title &&
              page.hero_title[0] &&
              page.hero_subtitle &&
              page.hero_subtitle[0] && (
                <section
                  css={`
                    position: relative;
                  `}
                >
                  <div
                    css={`
                      position: absolute;
                      top: 0;
                      right: 0;
                      left: 0;
                      z-index: 1;
                      transition: opacity 500ms ease-out 500ms,
                        transform 1s ease-out 500ms;
                    `}
                    style={{
                      opacity: revealChildren ? 1 : 0,
                      transform: revealChildren
                        ? "translate3d(0px, -100%, 0)"
                        : "translate3d(0, -95%, 0)",
                    }}
                  >
                    <Section pt={1} pb={1} bg="brand.rose">
                      <div
                        css={`
                          -ms-grid-column: 1;
                          -ms-grid-column-span: 23;
                          grid-column: 1 / 13;
                          -ms-grid-row: 1;
                          grid-row: 1;
                        `}
                      >
                        <H2
                          children={
                            page.hero_title &&
                            PrismicRichText.asText(page.hero_title)
                          }
                          mb="0.25em"
                          textAlign="center"
                          color="text.reverse"
                        />
                        <Text
                          children={
                            page.hero_subtitle &&
                            PrismicRichText.asText(page.hero_subtitle)
                          }
                          fontSize={1.2}
                          textAlign="center"
                          color="text.reverse"
                        />
                      </div>
                    </Section>
                  </div>
                </section>
              )}
            {page.builder_title && page.builder_title[0] && page.builders && (
              <Section
                pt={1}
                pb={1}
                bg="bg.wash"
                css={`
                  grid-row-gap: 2.5vw;
                `}
              >
                <div
                  css={`
                    display: flex;
                    justify-content: flex-start;
                    -ms-grid-column: 3;
                    -ms-grid-column-span: 5;
                    grid-column: 2 / 5;
                    -ms-grid-row: 1;
                    grid-row: 1;
                    ${(props) => props.theme.mediaQueries.medium} {
                      justify-content: center;
                      -ms-grid-column: 1;
                      -ms-grid-column-span: 15;
                      grid-column: 1 / 9;
                      text-align: center;
                    }
                    ${(props) => props.theme.mediaQueries.small} {
                      -ms-grid-column: 1;
                      -ms-grid-column-span: 7;
                      grid-column: 1 / 5;
                    }
                    ${(props) =>
                      props.theme.mediaQueries
                        .medium} and (-ms-high-contrast: none),
                  (-ms-high-contrast: active) {
                      margin-bottom: 2.5vw;
                    }
                  `}
                >
                  <Text
                    children={
                      page.builder_title &&
                      PrismicRichText.asText(page.builder_title)
                    }
                    fontSize={1.2}
                    css={`
                      align-self: center;
                      white-space: nowrap;
                    `}
                  />
                </div>
                <Link
                  to="/showhomes"
                  css={`
                    display: -ms-grid;
                    display: grid;
                    -ms-grid-columns: ${page.builders.length === 3
                      ? "1fr 5vw 1fr 5vw 1fr"
                      : page.builders.length === 4
                      ? "1fr 5vw 1fr 5vw 1fr 5vw 1fr"
                      : page.builders.length === 5
                      ? "1fr 5vw 1fr 5vw 1fr 5vw 1fr 5vw 1fr"
                      : "1fr 5vw 1fr 5vw 1fr"};
                    grid-template-columns: ${page.builders.length === 3
                      ? "1fr 5vw 1fr 5vw 1fr"
                      : page.builders.length === 4
                      ? "1fr 5vw 1fr 5vw 1fr 5vw 1fr"
                      : page.builders.length === 5
                      ? "1fr 5vw 1fr 5vw 1fr 5vw 1fr 5vw 1fr"
                      : "1fr 5vw 1fr 5vw 1fr"};
                    -ms-grid-column: 9;
                    -ms-grid-column-span: 13;
                    grid-column: 5 / 12;
                    -ms-grid-row: 1;
                    grid-row: 1;
                    justify-items: center;
                    align-items: center;
                    ${(props) => props.theme.mediaQueries.medium} {
                      -ms-grid-column: 1;
                      -ms-grid-column-span: 15;
                      grid-column: 1 / 9;
                      -ms-grid-row: 2;
                      grid-row: 2;
                    }
                    ${(props) => props.theme.mediaQueries.small} {
                      -ms-grid-column: 1;
                      -ms-grid-column-span: 7;
                      grid-column: 1 / 5;
                    }
                    > * {
                      width: 10vw;
                      height: 5vw;
                      mix-blend-mode: multiply;
                      opacity: 0.75;
                      ${(props) => props.theme.mediaQueries.medium} {
                        width: 15vw;
                        height: 10vw;
                      }
                    }
                  `}
                >
                  {imageData.builders.map((builder, index) => (
                    <div
                      css={`
                        -ms-grid-column: ${index * 2 + 1};
                        grid-column: ${index * 2 + 1};
                        -ms-grid-row: 1;
                        grid-row: 1;
                        width: 100%;
                      `}
                      key={"builder" + index}
                    >
                      <Image
                        src={
                          preview ? builder.logo && builder.logo.url : undefined
                        }
                        fluid={
                          preview
                            ? undefined
                            : data.prismicHome.data.builders[index].logo &&
                              data.prismicHome.data.builders[index].logo.fluid
                        }
                        alt={builder.logo && builder.logo.alt}
                        style={{ height: "100%" }}
                        objectFit="contain"
                        loading="eager"
                      />
                    </div>
                  ))}
                </Link>
              </Section>
            )}
            <Section pt={2} pb={3}>
              <div
                css={`
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 11;
                  grid-column: 1 / 7;
                  -ms-grid-row: 1;
                  grid-row: 1;
                  ${(props) => props.theme.mediaQueries.medium} {
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 15;
                    grid-column: 1 / 9;
                  }
                  ${(props) => props.theme.mediaQueries.small} {
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 7;
                    grid-column: 1 / 5;
                    margin: -10vw -5vw 0;
                  }
                `}
              >
                <FadeInGroup>
                  <Image
                    src={
                      preview
                        ? page.section_one_image_one &&
                          page.section_one_image_one.url
                        : undefined
                    }
                    fluid={
                      preview
                        ? undefined
                        : imageData.section_one_image_one &&
                          imageData.section_one_image_one.fluid
                    }
                    style={{ width: "100%" }}
                    alt={
                      imageData.section_one_image_one &&
                      imageData.section_one_image_one.alt
                    }
                  />
                </FadeInGroup>
              </div>
              <div
                css={`
                  -ms-grid-column: 13;
                  -ms-grid-column-span: 11;
                  grid-column: 7 / 13;
                  -ms-grid-row: 1;
                  grid-row: 1;
                  ${(props) => props.theme.mediaQueries.medium} {
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 15;
                    grid-column: 1 / 9;
                    -ms-grid-row: 2;
                    grid-row: 2;
                  }
                  ${(props) => props.theme.mediaQueries.small} {
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 7;
                    grid-column: 1 / 5;
                    margin: -10vw -5vw 0;
                  }
                  ${(props) =>
                    props.theme.mediaQueries
                      .medium} and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                    margin-top: 5vw;
                  }
                  ${(props) =>
                    props.theme.mediaQueries
                      .small} and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                    margin-top: 5vw;
                  }
                `}
              >
                <FadeInGroup>
                  <Image
                    src={
                      preview
                        ? page.section_one_image_two &&
                          page.section_one_image_two.url
                        : undefined
                    }
                    fluid={
                      preview
                        ? undefined
                        : data.prismicHome.data.section_one_image_two &&
                          data.prismicHome.data.section_one_image_two.fluid
                    }
                    css={`
                      margin-top: 33%;
                      ${(props) => props.theme.mediaQueries.medium} {
                        margin-top: 0;
                      }
                    `}
                    style={{ width: "100%" }}
                    alt={
                      page.section_one_image_two &&
                      page.section_one_image_two.alt
                    }
                  />
                </FadeInGroup>
              </div>
              <div
                css={`
                  margin-top: -5vw;
                  position: relative;
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 11;
                  grid-column: 1 / 7;
                  -ms-grid-row: 2;
                  grid-row: 2;
                  ${columnPush(1)};
                  @media all and (-ms-high-contrast: none),
                    (-ms-high-contrast: active) {
                    margin-top: 0;
                  }
                  ${(props) => props.theme.mediaQueries.medium} {
                    margin-top: 0;
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 15;
                    grid-column: 1 / 8;
                    -ms-grid-row: 4;
                    grid-row: 4;
                  }
                  ${(props) => props.theme.mediaQueries.small} {
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 7;
                    grid-column: 1 / 5;
                    padding-left: 0;
                  }
                  ${(props) =>
                    props.theme.mediaQueries
                      .medium} and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                    margin-top: 5vw;
                  }
                  ${(props) =>
                    props.theme.mediaQueries
                      .small} and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                    margin-top: 10vw;
                  }
                `}
              >
                <FadeGroup>
                  <H1
                    children={
                      page.section_one_title &&
                      PrismicRichText.asText(page.section_one_title)
                    }
                    mb="0.5em"
                  />
                  <RichText
                    children={
                      page.section_one_description &&
                      PrismicRichText.render(
                        page.section_one_description,
                        linkResolver,
                        serializer
                      )
                    }
                    mb="2em"
                  />
                  {page.section_one_button_text &&
                    page.section_one_button_link && (
                      <Button
                        children={page.section_one_button_text}
                        as={
                          page.section_one_button_link
                            ? page.section_one_button_link.link_type ===
                              "Document"
                              ? Link
                              : "a"
                            : "a"
                        }
                        href={page.section_one_button_link.url}
                        to={linkResolver(page.section_one_button_link)}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="peach"
                      />
                    )}
                </FadeGroup>
              </div>
              <div
                css={`
                  -ms-grid-column: 15;
                  -ms-grid-column-span: 11;
                  grid-column: 8 / 13;
                  -ms-grid-row: 2;
                  grid-row: 2;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  ${(props) => props.theme.mediaQueries.medium} {
                    -ms-grid-column: 3;
                    -ms-grid-column-span: 13;
                    grid-column: 2 / 8;
                    -ms-grid-row: 3;
                    grid-row: 3;
                  }
                  ${(props) => props.theme.mediaQueries.small} {
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 7;
                    grid-column: 1 / 5;
                  }
                  ${(props) =>
                    props.theme.mediaQueries
                      .medium} and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                    margin-top: 5vw;
                  }
                  ${(props) =>
                    props.theme.mediaQueries
                      .small} and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                    margin-top: 10vw;
                  }
                `}
              >
                <div
                  css={`
                    width: 100%;
                    align-self: flex-end;
                  `}
                >
                  <WalkingHomeSvg />
                </div>
              </div>
            </Section>
            <FadeGroup>
              <SplitImage
                src={
                  preview
                    ? page.section_two_main_image &&
                      page.section_two_main_image.url
                    : undefined
                }
                fluid={
                  preview
                    ? undefined
                    : data.prismicHome.data.section_two_main_image &&
                      data.prismicHome.data.section_two_main_image.fluid
                }
                alt={
                  page.section_two_main_image && page.section_two_main_image.alt
                }
                bg="brand.nickel"
              />
            </FadeGroup>
            <Section pb={3} bg="brand.nickel">
              <div
                css={`
                  position: relative;
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 11;
                  grid-column: 1 / 7;
                  -ms-grid-row: 1;
                  grid-row: 1;
                  ${columnPush(1)};
                  ${(props) => props.theme.mediaQueries.medium} {
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 15;
                    grid-column: 1 / 8;
                  }
                  ${(props) => props.theme.mediaQueries.small} {
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 7;
                    grid-column: 1 / 5;
                    padding-left: 0;
                  }
                `}
              >
                <FadeGroup>
                  <H1
                    children={
                      page.section_two_title &&
                      PrismicRichText.asText(page.section_two_title)
                    }
                    mb="0.5em"
                    color="text.reverse"
                  />
                  <RichText
                    children={
                      page.section_two_description &&
                      PrismicRichText.render(
                        page.section_two_description,
                        linkResolver,
                        serializer
                      )
                    }
                    mb="2em"
                    color="text.reverse"
                  />
                  {page.section_two_button_text &&
                    page.section_two_button_link && (
                      <Button
                        children={page.section_two_button_text}
                        as={
                          page.section_two_button_link
                            ? page.section_two_button_link.link_type ===
                              "Document"
                              ? Link
                              : "a"
                            : "a"
                        }
                        href={page.section_two_button_link.url}
                        to={linkResolver(page.section_two_button_link)}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="reverse"
                      />
                    )}
                </FadeGroup>
              </div>
            </Section>
            <Section
              pt={3}
              pb={3}
              css={`
                align-items: center;
                color: #000;
              `}
            >
              <div
                css={`
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 13;
                  grid-column: 1 / 8;
                  -ms-grid-row: 1;
                  grid-row: 1;
                  ${(props) => props.theme.mediaQueries.medium} {
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 15;
                    grid-column: 1 / 9;
                  }
                  ${(props) => props.theme.mediaQueries.small} {
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 7;
                    grid-column: 1 / 5;
                  }
                `}
              >
                <FadeInGroup>
                  <Image
                    src={
                      preview
                        ? page.anthem_main_image && page.anthem_main_image.url
                        : undefined
                    }
                    fluid={
                      preview
                        ? undefined
                        : data.prismicHome.data.anthem_main_image &&
                          data.prismicHome.data.anthem_main_image.fluid
                    }
                    alt={page.anthem_main_image && page.anthem_main_image.alt}
                    style={{ width: "100%" }}
                  />
                </FadeInGroup>
              </div>
              <div
                css={`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  -ms-grid-column: 15;
                  -ms-grid-column-span: 9;
                  grid-column: 8 / 13;
                  -ms-grid-row: 1;
                  grid-row: 1;
                  ${(props) => props.theme.mediaQueries.medium} {
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 15;
                    grid-column: 1 / 9;
                    -ms-grid-row: 2;
                    grid-row: 2;
                  }
                  ${(props) => props.theme.mediaQueries.small} {
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 7;
                    grid-column: 1 / 5;
                  }
                  ${(props) =>
                    props.theme.mediaQueries
                      .medium} and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                    margin-top: 5vw;
                  }
                  ${(props) =>
                    props.theme.mediaQueries
                      .small} and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                    margin-top: 10vw;
                  }
                `}
              >
                <AnthemUnitedLogo
                  css={`
                    ${fluidType(5)};
                    width: ${2000 / 787.75 + "em"};
                    height: 1em;
                    margin-bottom: 0.5em;
                    ${(props) => props.theme.mediaQueries.medium} {
                      width: ${(2000 / 787.75) * 1.5 + "em"};
                      height: 1.5em;
                    }
                  `}
                />
                <RichText
                  children={
                    page.anthem_description &&
                    PrismicRichText.render(
                      page.anthem_description,
                      linkResolver,
                      serializer
                    )
                  }
                  mb="2em"
                  css={`
                    color: #000 !important;
                  `}
                />
                <div
                  css={`
                    > * {
                      color: #000 !important;
                      &::before {
                        border: 1px solid #000 !important;
                      }
                      &::after {
                        background-color: #000 !important;
                      }
                      &:hover,
                      &:focus {
                        color: #fff !important;
                      }
                    }
                  `}
                >
                  {page.anthem_button_link && page.anthem_button_text && (
                    <Button
                      children={page.anthem_button_text}
                      as={
                        page.anthem_button_link
                          ? page.anthem_button_link.link_type === "Document"
                            ? Link
                            : "a"
                          : "a"
                      }
                      href={page.anthem_button_link.url}
                      to={linkResolver(page.anthem_button_link)}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Anthem United website"
                    />
                  )}
                </div>
              </div>
            </Section>
            {showPopup && (
              <Popup
              // text={
              //   page.popup_text && PrismicRichText.asText(page.popup_text)
              // }
              // buttonText={page.popup_button_text}
              // buttonLink={page.popup_button_link}
              />
            )}
          </>
        );
      }}
    />
  );
}

export default IndexPage;
